import {loc2dom, loc2jquery} from "./locations";

/*
<div id="mychart"></div>

<script>
    const example = new dkcharts.ColumnChart('#mychart', {
        column_names: ['a', 'b', 'c', 'd', 'e'],
        xlabel: "Bokstaver",
        ylabel: "Verdi",
        values: {
            'Kolonne 1': {
                'a': 1, 'b': 2, 'c':3, 'd':4, 'e':5
            },
            'Kolonne 2': {
                'a': 5, 'b': 4, 'c':3, 'd':2, 'e':1
            },
        },
        width: 600,
        height: 400,
        ymax: 5,
    })
</script>

*/

export class ColumnChart {
    constructor(location, options) {
        const loc = loc2dom(location);
        const width = options.width || options.size || 1000;
        const height = options.height || options.size || 1000;
        loc.style.width = `${width}px`;
        loc.style.height = `${height}px`;
        loc.classList.add('dkcharts', 'dkcharts-columnchart');

        this.column_names = options.column_names || [];
        this.values = options.values || [];

        const params = {
            chart: {
                type: 'column',
                styledMode: true,
            },
            title: options.title,
            subtitle: options.subtitle,
            exporting: {enabled: options.enable_export || false},
            // tooltip: {enabled: false},
            credits: {enabled: false},
            // plotOptions: {},

            xAxis: {
                categories: this.column_names,
                crosshair: true,
                title: {
                    text: options.xlabel,
                }
            },
            yAxis: {
                title: {
                    text: options.ylabel,
                },
                max: options.ymax || null,
            },
            tooltip: {
                formatter: function () {
                    return `<tr><td style="color:{series.color};padding:0; text-decoration: underline">${this.series.name}</td><br>
                            <tr><td style="color:{series.color};padding:0">${options.xlabel}:</td>
                            <td style="padding:0"><b>${this.x}</b></td></tr> <br>
                            <tr><td style="color:{series.color};padding:0">${options.ylabel}:</td>
                            <td style="padding:0"><b>${this.y}</b></td></tr> <br>`;
                },
                useHTML: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: this.valuesToSeries(this.values),
        };

        this.chart = Highcharts.chart(loc, params);
    }

    valuesToSeries(vals) {
        return Object.entries(vals).map(([k, v]) => this.valueToSerie(k, v));
    }

    valueToSerie(title, val) {
        return {
            name: title,
            data: this.column_names.map(name => val[name])
        };
    }

    set ymax(val) {
        this.chart.yAxis[0].update({max: val});
    }
}


export class BasicColumn {
    constructor(location, options) {
        const loc = loc2dom(location);
        const width = options.width || options.size || 1000;
        const height = options.height || options.size || 1000;
        loc.style.width = `${width}px`;
        loc.style.height = `${height}px`;
        loc.classList.add('dkcharts', 'dkcharts-basic-column');

        this.column_names = options.column_names || [];
        this.values = options.values || [];

        const params = {
            chart: {
                type: 'column'
            },
            title: {
                text: options.title
            },
            subtitle: {
                text: options.subtitle,
            },
            xAxis: {
                categories: this.column_names,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: options.ylabel
                }
            },
            tooltip: {
                formatter: function () {
                    return `<tr><td style="color:{series.color};padding:0; text-decoration: underline">${this.series.name}</td><br>
                            <tr><td style="color:{series.color};padding:0">${options.xlabel}:</td>
                            <td style="padding:0"><b>${this.x}</b></td></tr> <br>
                            <tr><td style="color:{series.color};padding:0">${options.ylabel}:</td>
                            <td style="padding:0"><b>${this.y}</b></td></tr> <br>`;
                },
                useHTML: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: this.valuesToSeries(this.values),
        };

        this.chart = Highcharts.chart(loc, params);
    }

    valuesToSeries(vals) {
        // console.log('vals::: ', vals)
        return Object.entries(vals).map(([k, v]) => this.valueToSerie(k, v));
    }

    valueToSerie(title, val) {
        return {
            name: title,
            data: this.column_names.map(name => val[name])
        };
    }

    set ymax(val) {
        this.chart.yAxis[0].update({max: val});
    }
}
